<template>
    <div class="main">
        <div v-if="!is_error" class="content">
            <div class="encr">
                <div>收款码牌ID</div>
                <div class="code">{{code}}</div>
            </div>
            <van-cell-group>
                <van-field
                        v-model="phone"
                        center
                        clearable
                        type="tel"
                        maxlength="11"
                        placeholder="请输入芝富通APP关联的手机号"
                >
                </van-field>
                <van-field
                        v-model="vcode" type="digit"
                        center
                        clearable
                        maxlength="4"
                        placeholder="请输入短信验证码"
                >
                    <template #button>
                        <div class="send-code" @click="sendCode">
                            <span v-if="!is_count_down">获取验证码</span>
                            <span v-else>{{count_down}}秒后重新发送</span>
                        </div>
                    </template>
                </van-field>

                <van-field
                        @click="show = true"
                        v-model="store_name"
                        center
                        clearable
                        placeholder="请选择门店(选填)" readonly
                >
                    <template #button>
                        <van-icon name="arrow-down" color="#c2c2c2"/>
                    </template>
                </van-field>
            </van-cell-group>
            <div class="btn">
                <van-button type="primary"
                            :color="(/^1[3-9]\d{9}$/.test(phone) && vcode.length == 4)?'#ef2505':'#fabdb3'"
                            style="width:100%;" round @click="submitData">确定
                </van-button>
            </div>
        </div>
        <div v-else class="content-pause">
            <div style="display: flex;flex-direction: column;align-items: center;">
                <van-image :src="code_blank" width="200px"></van-image>
                <div style="margin:20px 0px;">非法访问！</div>
                <div style="width: 260px;">
                    <!--                    <van-button round block v-if="is_alipay">重新扫码</van-button>-->
                </div>
            </div>
        </div>
        <van-action-sheet
                v-model="show"
                :actions="actions"
                @select="selectStore"
                cancel-text="取消"
                close-on-click-action
        />
    </div>

</template>

<script>
    import {Button, CellGroup, Field, Icon, Image, ActionSheet, Toast} from "vant";
    import qs from "qs";
    // eslint-disable-next-line no-unused-vars
    let timer;
    export default {
        name: "bind",
        data() {
            return {
                show: false,
                phone: '',
                vcode: '',
                code: '',
                is_error: false,
                code_blank: require("../../assets/code_blank.png"),
                actions: [],
                count_down: 60,
                is_count_down: false,
                store_name: '',
                store_info: null
            }
        },
        components: {
            [Image.name]: Image,
            [CellGroup.name]: CellGroup,
            [Field.name]: Field,
            [Icon.name]: Icon,
            [Button.name]: Button,
            [ActionSheet.name]: ActionSheet,
            [Toast.name]: Toast
        },
        mounted() {

            const query = this.$route.query;
            if (query.code == undefined) {
                this.is_error = true
                return;
            } else {
                this.code = query.code
                console.log(query)
                this.init()
            }
        },
        methods: {
            init() {
                console.log(this.code)
            },
            submitData() {
                if (!/^1[3-9]\d{9}$/.test(this.phone)) {
                    Toast('手机格式有误')
                    return
                }
                if(this.vcode.length != 4){
                    Toast('验证码格式有误')
                    return
                }
                let params = {
                    encrQrCode:this.code, //收款码编号
                    mobile:this.phone, //手机号
                    storeId:this.store_info != null?this.store_info.storeId:'', //门店Id
                    validateCode:this.vcode //手机验证码

                }
                this.$request.post('sys/collectQrCodeController/bindEncrQrCodeData',qs.stringify(params)).then((res)=>{
                    if(res.code == 200){
                        console.log(res)
                        this.$router.replace('bind_success?code='+this.code)
                    }
                })
            },
            sendCode() {
                if (/^1[3-9]\d{9}$/.test(this.phone)) {
                    if (!this.is_count_down) {

                        let params = {
                            mobile: this.phone
                        }
                        this.$request.post('sys/collectQrCodeController/sendValidateCodeToBind', qs.stringify(params)).then((res) => {
                            console.log(res)
                            if (res.code == 200) {
                                let _this = this;
                                this.count_down = 60;
                                this.is_count_down = true
                                this.getStores()
                                timer = setInterval(function () {

                                    _this.count_down = parseInt(_this.count_down) - 1;
                                    console.log(_this.count_down)
                                    if (_this.count_down == 0) {
                                        clearInterval(timer);
                                        _this.is_count_down = false;
                                    }
                                }, 1000);
                            }
                        })
                    }
                } else {
                    Toast('手机格式有误')
                }
            },
            getStores() {
                let params = {
                    mobile: this.phone
                }
                this.$request.post('sys/collectQrCodeController/storePage', qs.stringify(params)).then((res) => {
                    console.log(res)
                    let list = res.data;
                    for (let i = 0; i < list.length; i++) {
                        list[i].name = list[i].storeName;
                    }
                    this.actions = list;
                    this.store_info = null
                    this.store_name = ""
                })
            },
            selectStore(e) {
                console.log(e)
                this.store_name = e.storeName
                this.store_info = e;
            }
        }

    }
</script>

<style scoped lang="less">
    .main {
        min-height: 100vh;
        background: white;
        color: #888888;
    }

    .content {
        padding: 20px;

        .send-code {
            color: #ef2505;
        }

        .encr {
            text-align: center;

            .code {
                color: #2f2f2f;
                font-size: 40px;
                line-height: 60px;
            }
        }

        .btn {
            margin-top: 80px;
        }

        &-pause {
            padding-top: 100px;
        }
    }
</style>
<style>
    .van-field {
        margin-top: 30px;
    }

    [class*=van-hairline]::after {
        border: 0px;
    }

    .van-cell:after {
        border-bottom: 0px;
    }

    .van-cell, .van-action-sheet__item {
        border-bottom: 1px solid #ebedf0;
    }
</style>
